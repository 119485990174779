export default {
  public: [],
  admin: [
    {
      path: '/search-records',
      name: 'search-records',
      component: () => import('@/modules/accounting/views/searchRecords.vue'),
    },
  ],
}
